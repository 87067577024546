<template>
  <v-sheet class="promotion" style="height: calc(100vh - 140px)">
    <v-row>
      <!-- <pre>{{selectedRows}}</pre> -->
      <v-layout class="mx-2 overflow w-100 justify-start" style="max-width: 100%; overflow: auto">
        <!-- {{ selectedRows }} -->
        <!-- <v-col md="0" class="pl-0" min-width="245px" max-width="220px">
            <v-card class="" style="border: 0.5px solid #90caf9">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title
                      class="custom-form-label me-2"
                      style="font-weight: 600; font-size: 16px"
                    >
                      MONTH</v-list-item-title
                    >
                  </v-list-item-content>
                  <div align="center" justify="end">
                    <span class="subheading">
                      <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600">
                        01 Monday</v-chip
                      >
                    </span>
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col> -->
        <v-col md="0" class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #ef9a9a">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL POS</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#F44336"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(qrsales) }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #ab47bc">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL FEE</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="#8E24AA"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(fee) }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #81c784">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL ESTIMATED
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip
                      color="#1B5E20"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(estimated_fee) }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #00b0ff">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL RECEIVED
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip
                      color="#00B0FF"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ formatMoney(banking) }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="pl-0" min-width="245px" max-width="220px">
          <v-card class="" style="border: 0.5px solid #aed581">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content class="me-2">
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    TOTAL DISCREPANCY
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip
                      color="#558B2F"
                      style="font-size: 18px; color: white; font-weight: 600"
                    >
                      {{ formatMoney(discrepancy) }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col class="pl-0" min-width="245px" max-width="220px">
            <v-card class="" style="border: 0.5px solid #c2185b">
              <v-card-actions>
                <v-list-item class="grow px-0">
                  <v-list-item-content>
                    <v-list-item-title
                      class="custom-form-label"
                      style="font-weight: 600; font-size: 16px"
                    >
                      TOTAL SALES
                    </v-list-item-title>
                  </v-list-item-content>
                  <div align="center" justify="end">
                    <span class="subheading"
                      ><v-chip color="#C2185B" style="font-size: 18px; color: white; font-weight: 600"
                        >{{formatMoney(totalsales)}}</v-chip
                      ></span
                    >
                  </div>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col> -->
      </v-layout>
    </v-row>
    <v-row>
      <v-col md="12" class="pl-2" color="blue darken-4 ">
        <v-tabs
          active-class="custom-tab-active"
          v-model="productTab"
          background-color="transparent"
          color="blue darken-4"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#publish">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon"> </span>
            Debit Sales
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon"> </span>
            Banking In
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="productTab">
          <v-tab-item value="publish">
            <Debitsales
              v-on:saveAddress="updateAddressPerson"
              v-on:fee="fees"
              v-on:estimated_fee="estimated_fees"
              v-on:banking="bankings"
              v-on:discrepancy="discrepancys"
              :status="status"
            ></Debitsales>
          </v-tab-item>
          <v-tab-item value="other">
            <BankingIn
              v-on:saveAddress="updateAddressPerson"
              v-on:fee="fees"
              v-on:estimated_fee="estimated_fees"
              v-on:banking="bankings"
              v-on:discrepancy="discrepancys"
              :status="status"
            ></BankingIn>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import Debitsales from "./Debitsales.vue";
import BankingIn from "./BankingIn.vue";
import PosMixin from "@/core/mixins/pos.mixin";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
// import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
// import SelectInput from "@/view/components/SelectInput.vue";

export default {
  mixins: [PosMixin],

  name: "promotion-listing",
  title: "Listing Promotion",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      banking: 0,
      qrsales: 0,
      estimated_fee: 0,
      discrepancy: 0,
      fee: 0,
      type: 2,
      lastMonth: 0,
      status: [
        {
          name: "All",
          color: "blue",
        },
        {
          name: "Active",
          color: "green",
        },
        { name: "InActive", color: "red" },
        { name: "Draft", color: "cyan" },
      ],
      promotionTableHeading: [
        { title: "ACTION", status: true },
        { title: "IMAGES", status: true },
        { title: "TYPE", status: true },
        { title: "TITLE", status: true },
        { title: "DESCRIPTION", status: true },
        { title: "START DATE", status: true },
        { title: "EXPIRY DATE", status: true },
        { title: "PUBLISH DATE", status: true },
      ],
      promotionTableData: [
        {
          code: "XMH36",
          action: { value: "", key: "ACTION" },
          title: { value: "BUSINESS", key: "TITLE" },
          type: { value: "MEMBERS", key: "TYPE" },
          description: { value: "DESCRIPTION", key: "DESCRIPTION" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          expiry_date: { value: "28/08/2023", key: "END DATE" },
          publish_date: { value: "28/08/2023", key: "PUBLISH DATE" },
          images: {
            value: "https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png",
            key: "IMAGES",
          },
        },
        {
          code: "XMQ96",
          action: { value: "", key: "ACTION" },
          title: { value: "CAP STOCKER", key: "TITLE" },
          type: { value: "PREMIUM", key: "TYPE" },
          description: { value: "DESCRIPTION", key: "DESCRIPTION" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          expiry_date: { value: "28/08/2023", key: "END DATE" },
          publish_date: { value: "28/08/2023", key: "PUBLISH DATE" },
          images: {
            value:
              "https://plus.unsplash.com/premium_photo-1661953124283-76d0a8436b87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            key: "IMAGES",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      promotions: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      promotionId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },

  methods: {
    updateAddressPerson(param) {
      this.qrsales = param;
    },
    estimated_fees(param) {
      this.estimated_fee = param;
    },
    estimated_banking_fee(param) {
      this.estimated_fee = param;
    },
    fees(param) {
      this.fee = param;
    },
    bankings(param) {
      this.banking = param;
    },
    discrepancys(param) {
      this.discrepancy = param;
    },

    routeToUpdate(id) {
      this.$router.push({
        name: "promotion-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "debitsales-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
  },
  async mounted() {
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "DEBIT SALES");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    // DeleteTemplate,
    // OrderTemplate,
    // CollectTemplate,
    // RedeemTemplate,
    // SearchTemplate,
    // SearchCriteria,
    // SelectInput,
    // Dialog,
    // DateRangePicker,
    // ValueTemplate,\
    Debitsales,
    BankingIn,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
    productTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        query.t = new Date().getTime();
        if (val != this.productTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "publish";
      },
    },
  },
};
</script>

<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 360px);
}

.promotion-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.promotion-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
.custom_field_height v-input__control {
  max-height: 34px;
  height: 34px;
}
.custom_field_height .v-select__selection {
  max-width: 84%;
}
</style>
